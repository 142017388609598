@import 'shared/scss-helpers/mixins.scss';
@import 'shared/scss-helpers/colors.scss';

:root {
  --connectionsbot-walking-icon: url('/public/assets/connections/connections-bot-mascot.svg');
  --wordlebot-walking-icon: url('https://www.nytimes.com/games-assets/v2/assets/wordle/nav-icons/WordleBot-Walking-Icon-Normalized.svg');
}

.botLink {
  display: flex;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 16px 0;
  text-decoration: none;
  color: inherit;

  &.borderTop {
    border-top: 1px solid $gray19;
  }

  &.borderBottom {
    border-bottom: 1px solid $gray19;
    margin-bottom: 24px;
  }

  &.bordered {
    border-top: 1px solid $gray19;
    border-bottom: 1px solid $gray19;
    margin-bottom: var(--vertical-spacing-3);
  }

  .botIcon {
    padding-right: 2px;
    height: 32px;
    width: 31px;
    margin-right: 8px;
  }

  .botIcon--wordlebot {
    content: var(--wordlebot-walking-icon);
  }

  .botIcon--connectionsbot {
    content: var(--connectionsbot-walking-icon);
  }

  .largeIcon {
    height: 37px;
    width: 37px;
  }

  .botLinkContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    p {
      font: 700 14px/17.5px 'nyt-franklin';
      margin: 0;
      padding-right: 5px;

      .botLinkHref {
        font-weight: 400;

        &__gamesApp {
          margin-left: 3px;
        }
      }
    }
  }

  .archive {
    p {
      font: 700 16px/18.4px 'nyt-franklin';

      .botLinkHref {
        text-decoration: none;
      }
    }
  }

  .mobileOnly {
    display: block;

    @media (min-width: 500px) {
      display: none;
    }
  }
}
