// ------------------
// SPACING
// Reference:
// https://www.figma.com/file/mWQFnNjosICL4B1FPN74Rs/Games-Design-Tokens?node-id=1567%3A565
// ------------------

// For use with margin or padding

$spacing-0: 0; // 0px
$spacing-1: 0.5em; // 8px
$spacing-1-5: 0.75em; // 12px
$spacing-2: 1em; // 16px
$spacing-2-5: 1.25em; //20px
$spacing-4: 2em; // 32px
$spacing-5: 2.5em; // 40px
$spacing-5-5: 2.75em; // 44px
$spacing-8: 4em; // 64px
