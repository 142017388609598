@import 'shared/scss-helpers/mixins.scss';
@import 'shared/scss-helpers/index.scss';
@import 'shared/scss-helpers/variables.scss';

/* Global Styles & Colors */
$itemWidth--small: 91px;
$itemWidth--medium: 106px;
$itemWidth--large: 123px;

:root {
  // Icons
  --wordle-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Wordle-Icon-np.svg');
  --strands-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Strands-Icon-np.svg');
  --spelling-bee-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Spelling-Bee-Icon-np.svg');
  --mini-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Mini-Icon-np.svg');
  --connections-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Connections-Icon-np.svg');
  --sudoku-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Sudoku-Icon-np.svg');
  --tiles-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Tiles-Icon-np.svg');
  --daily-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Crossword-Icon-np.svg');

  // Colors
  --wordleGray: #{$gray13};
  --strandsGreen: #{$strands-seafoam};
  --spellingBeeYellow: #{$spelling-bee-gold};
  --miniCrosswordBlue: #{$mini-crossword-blue};
  --connectionsPeriwinkle: #{$connections-periwinkle};
  --sudoku-orange: #{$sudoku-orange};
  --tiles-green: #{$tiles-green};
  --dailyCrosswordBlue: #{$daily-crossword-blue};
  --color-tone-1: #{$black};
}

.wa {
  background-color: var(--wordleGray);

  .icon {
    background-image: var(--wordle-np);
  }
}

.strands {
  background-color: var(--strandsGreen);

  .icon {
    background-image: var(--strands-np);
  }
}

.sb {
  background-color: var(--spellingBeeYellow);

  .icon {
    background-image: var(--spelling-bee-np);
  }
}

.mini {
  background-color: var(--miniCrosswordBlue);

  .icon {
    background-image: var(--mini-np);
  }
}

.connections {
  background-color: var(--connectionsPeriwinkle);

  .icon {
    background-image: var(--connections-np);
  }
}

.sudoku {
  background-color: var(--sudoku-orange);

  .icon {
    background-image: var(--sudoku-np);
  }
}

.tiles {
  background-color: var(--tiles-green);

  .icon {
    background-image: var(--tiles-np);
  }
}

.crossword {
  background-color: var(--dailyCrosswordBlue);

  .icon {
    background-image: var(--daily-np);
  }
}

.carouselGameItem {
  height: $itemWidth--small;
  scroll-snap-align: center;
  width: 100%;

  @media (min-height: $se-height) {
    height: $itemWidth--medium;
    min-width: $itemWidth--medium;
    flex: 0 0 $itemWidth--medium;
  }

  @include view-width-min(md) {
    height: $itemWidth--medium;
    width: 111px;
  }
}

.carouselGameItem,
.gamesGridItem {
  border-radius: 0.5rem;
  color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.carouselGameItem .icon,
.gamesGridItem .icon {
  background-size: contain;
  background-repeat: no-repeat;
}

.carouselGameItem .icon {
  width: 28px;
  height: 28px;

  @media (min-width: 768px) {
    width: 25px;
    height: 25px;
  }
}

.gamesGridItem .icon {
  width: 25px;
  height: 25px;
}

.carouselGameItem p,
.gamesGridItem p {
  font: 700 rem(14) / rem(19) 'nyt-karnakcondensed';
  margin: 5px 0 2px;
  max-width: 95px;

  @media (min-height: $se-height) {
    font-size: 0.96rem;
  }

  @media (min-width: 768px) {
    font-size: 0.96rem;
  }
}

.carouselGameItem span,
.gamesGridItem span {
  font: 500 rem(12) / rem(16) 'nyt-franklin';

  @media (min-height: $se-height) {
    font-size: rem(12);
    line-height: rem(18);
  }
}

// Game Stack
.frictionMitigationContent {
  font-family: 'nyt-franklin';

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-height: $se-height) {
    margin-bottom: 20px;
  }

  :global(.pz-desktop) & {
    margin-bottom: 0px;
  }

  .carouselGameItem,
  .gamesGridItem {
    position: relative;
  }

  .carouselStackContainer {
    width: 100%;
    margin: 0 auto;
    position: relative;

    hr {
      display: inline-block;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-top: solid 2px var(--color-tone-1);
      margin-bottom: 0;
    }
  }

  h4 {
    font: 700 rem(11) / rem(13.75) 'nyt-franklin';
    letter-spacing: 0.05em;
    padding: 10px 0 16px;
    text-align: left;

    @include view-width-min(md) {
      margin-bottom: 12px;
      padding-bottom: 0;
    }
  }

  .gridContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .gamesStack {
    display: grid;
    grid-template-columns: repeat(3, $itemWidth--medium);
    grid-template-rows: repeat(2, $itemWidth--medium);
    grid-row-gap: 10px;
    grid-column-gap: 11px;
    margin-bottom: 3em;

    .carouselGameItem {
      height: $itemWidth--medium;
      min-width: $itemWidth--medium;
    }

    @media (max-width: 360px) {
      // for exceptionally small screens such as the Galaxy S9+
      grid-template-columns: repeat(3, $itemWidth--small);
      .carouselGameItem {
        height: $itemWidth--small;
        min-width: $itemWidth--small;
      }
    }

    &:after {
      display: none;
    }

    @include view-width-min(md) {
      grid-template-columns: repeat(3, 111px);
    }
  }
}

.lockIcon {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 32px;
  height: 32px;
  background-image: url('/public/assets/spelling-bee-hub/lock-unfiltered.svg');
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.25));
}
