@import '../../../shared/scss-helpers/colors.scss';

// light themed colors for the toolbar and dropdown
.toolbarColors {
  background-color: $white;
  color: $newsGray100;

  &_border {
    border: 1px solid $newsGray10;

    &Bottom {
      border: unset;
      border-bottom: 1px solid $newsGray10;
    }
  }

  &:hover {
    background-color: $gray12;
  }
}

// prevent touch hover style from persisting on devices that don't native support "hover", like mobile
@media (hover: none) {
  .toolbarColors {
    &:hover {
      background-color: $white;
    }
  }
}

// dark themed colors for the toolbar and dropdown
:global(.dark) {
  .toolbarColors {
    background-color: $newsGray100;
    color: $newsDarkContentPrimary;

    &_border {
      border: 1px solid $newsGray10;

      &Bottom {
        border: unset;
        border-bottom: 1px solid $gray27;
      }
    }

    &:hover {
      background-color: $newsGray85;
    }
  }

  // prevent touch hover style from persisting on devices that don't native support "hover", like mobile
  @media (hover: none) {
    .toolbarColors {
      &:hover {
        background-color: var(--color-tone-7);
      }
    }
  }
}
