@import './../../../shared/scss-helpers/index.scss';
@import './index.scss';
@import 'shared/scss-helpers/mixins.scss';

.header {
  width: 100%;
}

.toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: $nav-height-mobile;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
}

button,
a {
  align-items: center;
  cursor: pointer !important;
  &:focus-visible {
    outline: 2px solid $outlineBlue;
    outline-offset: -1px;
  }
}

/* Game Specific styles*/
.wordle {
  height: $nav-height-mobile;

  @extend .toolbarColors_borderBottom;
  justify-content: space-between;
  padding: 0 var(--horizontal-spacing-2) 0 0;

  /* Responsive styles */
  @media (min-width: 768px) {
    padding: 0 var(--horizontal-spacing-2);
  }

  @media (min-width: 1024px) {
    padding: 0 var(--horizontal-spacing-3);
    height: $nav-height-desktop;
  }
}

/* Dark Theme Colors */
.dark {
  color: $newsDarkContentPrimary;
  border-bottom: 1px solid $gray26;
}
