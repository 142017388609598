@import '../../../shared/scss-helpers/colors.scss';
@import './index.scss';

.hamburger {
  @media (min-width: 768px) {
    margin: 0 0 0 calc(var(--horizontal-spacing-0-5) * -2.5);
  }

  @media (min-width: 1024px) {
    margin: 0 0 0 calc(var(--horizontal-spacing-0-5) * -3);
  }
}

.toolbar_item {
  @extend .toolbarColors;
  font-size: 1.125em;
  padding: 0 calc(var(--horizontal-spacing-0-5) * 2.5);
  border: none;
  display: flex;
  height: 100%;

  @media (min-width: 1024px) {
    padding: 0 var(--horizontal-spacing-1-5);
  }

  &:hover {
    cursor: pointer;
  }
}

.icon {
  height: 20px;
  width: 24px;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-size: contain;
  display: inline-block;
}
